import { IRouteDefinition } from 'providers';

import * as views from '.';

const routeConfig: IRouteDefinition[] = [
  {
    path: '/login',
    exact: true,
    component: views.Login,
    authenticated: false,
  },
];

export default routeConfig;
