import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { IRouteDefinition } from 'providers';

import * as views from '.';

const JudgmentRedirect: React.FC<{ to: string }> = ({ to }) => {
  const { pathname } = useLocation();
  return <Redirect to={`${pathname}/${to}`} />;
};

const routeConfig: IRouteDefinition[] = [
  {
    path: '/judgments',
    exact: true,
    component: views.JudgmentsOverviewPage,
    authenticated: true,
  },
  {
    path: '/judgments/:id',
    exact: true,
    component: () => <JudgmentRedirect to="overview" />,
    authenticated: true,
  },
  {
    path: '/judgments/:id/:section',
    exact: true,
    component: views.JudgmentDetailPage,
    authenticated: true,
  },
];

export default routeConfig;
