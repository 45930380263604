import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory, History } from 'history';

import { ApiProvider } from 'providers/Api/ApiProvider';
import { AuthProvider } from 'hooks/Firebase';
import { RouteConfig, IRouteDefinition } from './RouteConfig';

type AppProviderProps = {
  /**
   * Route configuration for the application
   */
  routes: IRouteDefinition[];

  /**
   * The history object to use for routing. Defaults to a browser history (HTML5 history API).
   */
  history?: History;
};

export const AppProvider: React.FC<AppProviderProps> = ({
  routes,
  history = createBrowserHistory(),
}: AppProviderProps) => {
  return (
    <div data-testid="app-root">
      <AuthProvider>
        <ApiProvider>
          {/**
           * React-router container -- everything that is rendered should be rendered
           * in the context of a URL. This component should never render anything
           * directly: instead, it is initialized with the global route config.
           */}
          <Router history={history}>
            <RouteConfig routes={routes} />
          </Router>
        </ApiProvider>
      </AuthProvider>
    </div>
  );
};

export default AppProvider;
