import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import {
  EuiLink,
  EuiHorizontalRule,
  EuiIcon,
  EuiAccordion,
  EuiSpacer,
  EuiTabbedContent,
  EuiTabbedContentTab,
} from '@elastic/eui';

import { useEventLogger } from 'hooks';
import { AppPage } from 'components';
import { Actor } from 'types/Actor';

import { HeroImage } from './HeroImage';
import { Profile } from './Profile';
import { Appearances } from './Appearances';

type ActorDetailProps = { actor: Actor };

const TabContainer: React.FC = ({ children }) => (
  <>
    <EuiHorizontalRule />
    <EuiSpacer size="l" />
    {children}
  </>
);

export const ActorDetail: React.FC<ActorDetailProps> = ({ actor }) => {
  const { profile } = actor;
  const location = useLocation();
  const history = useHistory();
  const logEvent = useEventLogger();
  const { section: activeSection = 'profile' } = useParams();

  const tabs: EuiTabbedContentTab[] = useMemo(
    () =>
      [
        {
          id: 'appearances',
          name: 'Appearances',
          content: <Appearances actor={actor} />,
        },
        {
          id: 'profile',
          name: <>Profile {profile && <EuiIcon type="starFilled" color="primary" size="m" />}</>,
          content: profile ? (
            <Profile actor={actor} />
          ) : (
            <>
              <i>No profile information available</i>{' '}
            </>
          ),
        },
      ].map(tab => ({ ...tab, content: <TabContainer>{tab.content}</TabContainer> })),
    [location.pathname],
  );

  const [selectedTabIdx, setSelectedTabIdx] = useState<number>(0);
  const selectedTab = tabs[selectedTabIdx];

  const getTabById = (id?: string): EuiTabbedContentTab | undefined =>
    tabs.find(tab => tab.id === id);

  const getIdxById = (id?: string): number => {
    let idx = 0;
    tabs.forEach((tab, i) => {
      if (tab.id == id) idx = i;
    });
    return idx;
  };

  const onTabClick = (clickedTab: EuiTabbedContentTab) => {
    logEvent('actor-detail-tab', clickedTab.id);
    // const newTab = tabs.find(tab => tab.id === selectedTab.id);
    // if (newTab) setSelectedTab(newTab);
    history.push(`/actors/${actor.id}/${clickedTab.id}`);
  };

  useEffect(() => {
    if (selectedTab.id !== activeSection) {
      const newTab = getTabById(activeSection);

      /**
       * Redirect to initial tab if an invalid path is given
       */
      if (!newTab) setSelectedTabIdx(0);
      else setSelectedTabIdx(getIdxById(newTab.id));
    }
  }, [activeSection, history.location.pathname]);

  return (
    <AppPage>
      <h1>{profile ? profile.name_styled : actor.name}</h1>

      {profile && <HeroImage url={profile.hero_image} />}

      <EuiHorizontalRule />

      <EuiTabbedContent
        expand
        display="condensed"
        tabs={tabs}
        selectedTab={selectedTab}
        onTabClick={onTabClick}
      />
    </AppPage>
  );
};
export default ActorDetail;
