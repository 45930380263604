import React, { useEffect, useState } from 'react';
import { EuiInMemoryTable, EuiLink } from '@elastic/eui';

import { AppPage } from 'components';
import { useApi } from 'providers';
import { ShortJudgment } from 'types';
import { EuiPageBody, EuiLoadingSpinner } from '@elastic/eui';

import { JudgmentsOverview } from './JudgmentsOverview';

export const JudgmentsOverviewPage = () => {
  const [{ pendingOrLoading, data = [] }, request] = useApi<ShortJudgment[]>({
    url: '/appearedin/judgments/',
  });

  useEffect(() => {
    request();
  }, [request]);

  if (pendingOrLoading) return <EuiLoadingSpinner />;

  return <JudgmentsOverview judgments={data} />;
};
