import React, { ReactPropTypes } from 'react';
import {
  EuiLink,
  EuiLoadingContent,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPage,
  EuiPageContent,
  EuiPageBody,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiPageHeader,
  EuiCard,
  EuiText,
  EuiTitle,
  EuiPageHeaderSection,
  EuiProgress,
  EuiPanel,
} from '@elastic/eui';

import { ranked } from 'utils';
import * as types from 'types';
import { Party } from './Party';

const Parties: React.FC<{ parties: types.Party[]; party_type: string }> = ({
  parties,
  party_type,
}) => (
  <>
    {ranked(parties)
      .filter(party => party.party_type === party_type)
      .map(party => (
        <Party party={party} />
      ))}
  </>
);

const PartiesApellantRespondent: React.FC<{ group: types.PartyGroup }> = ({ group }) => (
  <EuiPanel>
    <EuiFlexGroup>
      <EuiFlexItem>
        <Parties parties={group.parties} party_type="appellant" />
      </EuiFlexItem>
      <EuiFlexItem>
        <Parties parties={group.parties} party_type="respondent" />
      </EuiFlexItem>
    </EuiFlexGroup>
    <EuiFlexGroup>
      <EuiFlexItem>
        <Parties parties={group.parties} party_type="cross_appellant" />
      </EuiFlexItem>
      <EuiFlexItem>
        <Parties parties={group.parties} party_type="cross_respondent" />
      </EuiFlexItem>
    </EuiFlexGroup>
  </EuiPanel>
);

const PartiesIntervenorsAndOthers: React.FC<{ group: types.PartyGroup }> = ({ group }) => (
  <>
    <EuiPanel>
      <EuiFlexGroup>
        {ranked(group.parties)
          .filter(p => p.party_type === 'intervener')
          .map(party => (
            <EuiFlexItem>
              <Party party={party} />
            </EuiFlexItem>
          ))}
      </EuiFlexGroup>
    </EuiPanel>
  </>
);

export const PartyGroups: React.FC<{
  groups: types.PartyGroup[];
}> = ({ groups }) => {
  return (
    <>
      {ranked(groups).map(pg =>
        pg.non_appellant_respondent ? (
          <PartiesIntervenorsAndOthers group={pg} />
        ) : (
          <PartiesApellantRespondent group={pg} />
        ),
      )}
    </>
  );
};
