import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { EuiSuggest, EuiSuggestItemProps } from '@elastic/eui';
import useDebounce from 'react-use/lib/useDebounce';

import { Judgment, Actor } from 'types';
import './style.css';

type SuggestProps = {
  judgments: Judgment[];
  actors: Actor[];
  onSearch: (query: string) => void;
  debounceInterval?: number;
  loading?: boolean;
};

export const Suggest: React.FC<SuggestProps> = ({
  judgments,
  actors,
  onSearch,
  debounceInterval = 250,
  loading,
}) => {
  const history = useHistory();
  const [inputValue, setInputValue] = useState('');
  const [judgmentOptions, setJudgmentOptions] = useState<Judgment[]>(judgments);
  const [actorOptions, setActorOptions] = useState<Actor[]>(actors);

  useDebounce(
    () => {
      setJudgmentOptions([]);
      setActorOptions([]);
      onSearch(inputValue);
    },
    debounceInterval,
    [inputValue],
  );

  useEffect(() => {
    setJudgmentOptions(judgments);
    setActorOptions(actors);
  }, [judgments, actors]);

  type Suggestion = EuiSuggestItemProps & {
    data: Actor | Judgment;
    itemType: 'actor' | 'judgment';
  };
  const suggestions: Suggestion[] = [];

  judgmentOptions.forEach(judgment =>
    suggestions.push({
      type: { iconType: 'documents', color: 'tint1' },
      label: judgment.short_name,
      description: judgment.neutral_citation,
      className: 'ai-suggest-item',
      data: judgment,
      itemType: 'judgment',
    }),
  );

  actorOptions.forEach(actor =>
    suggestions.push({
      type: { iconType: 'user', color: 'tint4' },
      label: actor.name,
      description: 'Actor',
      className: 'ai-suggest-item',
      data: actor,
      itemType: 'actor',
    }),
  );

  const onItemClick = (clickedItem: EuiSuggestItemProps) => {
    const item = clickedItem as Suggestion;
    switch ((item as Suggestion).itemType) {
      case 'actor':
        history.push(`/actors/${item.data.id}`);
        break;
      case 'judgment':
        history.push(`/judgments/${item.data.id}`);
        break;
      default:
        break;
    }
  };

  return (
    <EuiSuggest
      onItemClick={onItemClick}
      suggestions={suggestions}
      status={loading ? 'loading' : undefined}
      onInputChange={(event: any) => setInputValue(event.value)}
      sendValue={(...args: any[]) => console.log(args)}
    />
  );
};

export default Suggest;
