import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { IRouteDefinition } from 'providers';

import * as views from '.';

const ActorRedirect: React.FC<{ to: string }> = ({ to }) => {
  const { pathname } = useLocation();
  return <Redirect to={`${pathname}/${to}`} />;
};

const routeConfig: IRouteDefinition[] = [
  {
    path: '/actors/:id',
    exact: true,
    component: () => <ActorRedirect to="/appearances" />,
    authenticated: true,
  },
  {
    path: '/actors/:id/:section',
    exact: true,
    component: views.ActorDetailPage,
    authenticated: true,
  },
];

export default routeConfig;
