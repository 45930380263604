import React from 'react';

import * as types from 'types';
import { Provenance } from './Provenance';

type ProceduralBackgroundProps = {
  judgment: types.Judgment;
};

export const ProceduralBackground: React.FC<ProceduralBackgroundProps> = ({ judgment }) => (
  <div>
    <Provenance judgment={judgment} />
  </div>
);

export default ProceduralBackground;
