import { useFirebase } from '../Firebase';

export const useEventLogger = () => {
  const firebase = useFirebase();

  return (eventName: string, params?: any) => {
    try {
      firebase.analytics().logEvent(eventName, params);
    } catch (err) {
      console.error(err);
    }
  };
};

export default useEventLogger;
