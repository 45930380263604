import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EuiProgress } from '@elastic/eui';

import { useApi } from 'providers';
import { Judgment } from 'types';

import { JudgmentDetail } from './JudgmentDetail';

export const JudgmentDetailPage = () => {
  const { id } = useParams();
  const [{ pendingOrLoading, data: judgment, status }, request] = useApi<Judgment>({
    url: `/appearedin/judgments/${id}`,
    validateStatus: status => [200].includes(status),
  });

  useEffect(() => {
    request().catch(err => console.error(err));
  }, [request]);

  if (pendingOrLoading) return <EuiProgress size="l" color="accent" />;

  if (status !== 200) return <div />;

  return <JudgmentDetail judgment={judgment} />;
};
