/* eslint-disable import/prefer-default-export */

/**
 * Export all application routes here as named route configs
 *
 * E.g.:
 * export { default as authRoutes } from './Auth/routes';
 */
export { default as authRoutes } from './Auth/routes';
export { default as landingRoutes } from './Landing/routes';
export { default as judgmentsRoutes } from './Judgments/routes';
export { default as actorsRoutes } from './Actors/routes';
export default [];
