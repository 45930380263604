import React from 'react';
import { Box, Flex } from 'reflexbox';

import { pickChildByComponent, pickChildrenByComponent } from 'utils';

type ChildrenOnlyProps = {
  children: React.ReactNode;
};

type AppScreenProps = ChildrenOnlyProps;

const createChildrenOnlyContainer: () => React.FC<ChildrenOnlyProps> = () => {
  const component: React.FC<ChildrenOnlyProps> = ({ children }) => <>{children}</>;
  return component;
};

const GlobalNav: React.FC<ChildrenOnlyProps> = createChildrenOnlyContainer();
const ContextNav: React.FC<ChildrenOnlyProps> = createChildrenOnlyContainer();
const Footer: React.FC<ChildrenOnlyProps> = createChildrenOnlyContainer();
const Main: React.FC<ChildrenOnlyProps> = createChildrenOnlyContainer();

interface IAppScreenComposition {
  Main: typeof Main;
  ContextNav: typeof ContextNav;
  Footer: typeof Footer;
  GlobalNav: typeof GlobalNav;
}

export const AppScreen: React.FC<AppScreenProps> & IAppScreenComposition = ({ children }) => {
  const pickedChildren = pickChildrenByComponent(children, [GlobalNav, ContextNav, Main, Footer]);

  const globalNav = pickChildByComponent(pickedChildren, GlobalNav);
  const contextNav = pickChildByComponent(pickedChildren, ContextNav);
  const main = pickChildByComponent(pickedChildren, Main);
  const footer = pickChildByComponent(pickedChildren, Footer);

  return (
    <Flex flexDirection="column" minHeight="100vh" flexWrap="wrap">
      {globalNav && <Box width={1}>{globalNav}</Box>}
      {contextNav ? (
        <Flex>
          <Box p={3} width={1 / 5}>
            {contextNav}
          </Box>

          <Box p={3} width={4 / 5}>
            {main}
          </Box>
        </Flex>
      ) : (
        <Box p={3} width={1}>
          {main}
        </Box>
      )}
      {footer && (
        <Box p={3} width={1}>
          {footer}
        </Box>
      )}
    </Flex>
  );
};

AppScreen.Main = Main;
AppScreen.Footer = Footer;
AppScreen.GlobalNav = GlobalNav;
AppScreen.ContextNav = ContextNav;

export default AppScreen;
