import React from 'react';
import ReactMarkdown from 'react-markdown';

import { Actor } from 'types/Actor';

type ProfileBodyProps = { actor: Actor };

export const ProfileBody: React.FC<ProfileBodyProps> = ({ actor }) => {
  return <ReactMarkdown source={actor.profile?.body_text} />;
};

export default ProfileBody;
