import React from 'react';
import { Link } from 'react-router-dom';
import {
  EuiBasicTable,
  EuiLink,
  EuiBadge,
  EuiHealth,
  EuiCard,
  EuiPanel,
  EuiFlexGroup,
  EuiIcon,
  EuiCallOut,
  EuiFlexItem,
} from '@elastic/eui';

import * as types from 'types';

type DecisionProps = {
  decision: types.Decision;
};

export const Decision: React.FC<DecisionProps> = ({ decision }) => {
  return (
    <EuiPanel>
      <EuiFlexGroup>
        <EuiFlexItem>
          <h2>{decision.decision_type_verbose}</h2>
          {decision.description}
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexItem>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiCallOut
                  title={
                    <>
                      <strong>{decision.outcome_verbose}</strong>
                      <br />
                      {decision.majority.length} Votes
                    </>
                  }
                  size="s"
                  color="success"
                  iconType="checkInCircleFilled"
                />
                {decision.majority.map(vote => (
                  <>
                    <div>
                      <Link to={`/actors/${vote.actor.id}`}>
                        {vote.actor.justice_style}{' '}
                        {vote.dissenting_in_part && <strong> (dissenting in part)</strong>}
                      </Link>
                      <br />
                    </div>
                  </>
                ))}
              </EuiFlexItem>
              {decision.is_unanimous === false && (
                <EuiFlexItem>
                  {Object.entries(decision.minority).map(([outcome, votes]) => (
                    <>
                      <EuiCallOut
                        title={
                          <>
                            <strong>{outcome}</strong>
                            <br />
                            {votes.length} Vote{votes.length > 1 && 's'}
                          </>
                        }
                        size="s"
                        color="warning"
                      />
                      {votes.map(vote => (
                        <Link to={`/actors/${vote.actor.id}`}>
                          {vote.actor.justice_style}{' '}
                          {vote.dissenting_in_part && <strong> (dissenting in part)</strong>}
                        </Link>
                      ))}
                    </>
                  ))}
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );
};

export default Decision;
