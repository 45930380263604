import React from 'react';
import { Link } from 'react-router-dom';

import * as types from 'types';

type InstructorProps = {
  instructor: types.Instructor;
};

export const Instructor: React.FC<InstructorProps> = ({ instructor }) => (
  <div>
    <Link to={`/actors/${instructor.actor.id}`}>{instructor.styled_as}</Link>
  </div>
);

export default Instructor;
