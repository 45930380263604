import React from 'react';

import { Actor } from 'types/Actor';
import { ProfileBody } from './ProfileBody';

type ProfileProps = {
  actor: Actor;
};

export const Profile: React.FC<ProfileProps> = ({ actor }) => {
  const { profile } = actor;

  return (
    <div>
      <ProfileBody actor={actor} />
    </div>
  );
};
export default Profile;
