import React from 'react';
import { Link } from 'react-router-dom';
import { EuiBadge, EuiIconTip } from '@elastic/eui';

import * as types from 'types';

export const Advocate: React.FC<{
  advocate: {
    id: number;
    styled_as: string;
    actor: types.Actor;
    capacities: types.AdvocateCapacity[];
  };
}> = ({ advocate }) => {
  return (
    <>
      <Link to={`/actors/${advocate.actor.id}`}>{advocate.styled_as}</Link>{' '}
      {advocate.capacities.length > 0 && (
        <EuiIconTip
          type="iInCircle"
          content={advocate.capacities.map(({ capacity }) => capacity).join(', ')}
        />
      )}
    </>
  );
};
