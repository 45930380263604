import React, { useMemo, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  EuiBadge,
  EuiBadgeGroup,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiHorizontalRule,
  EuiFlexItem,
  EuiPage,
  EuiPageContent,
  EuiPageBody,
  EuiText,
  EuiTabbedContentTab,
  EuiStat,
  EuiTabbedContent,
  EuiIconProps,
  EuiIcon,
  EuiSpacer,
  IconType,
  EuiPanel,
  EuiCard,
} from '@elastic/eui';

import * as types from 'types';
import { AppPage } from 'components';
import { useEventLogger } from 'hooks';
import { PartiesRepresentation } from './PartiesRepresentation';
import { Decisions } from './Decisions';
import { ProceduralBackground } from './ProceduralBackground';
import { AuthorshipContent } from './AuthorshipContent';

const TabContainer: React.FC = ({ children }) => (
  <>
    <EuiHorizontalRule />
    <EuiSpacer size="l" />
    {children}
  </>
);

export const JudgmentDetail: React.FC<{ judgment: types.Judgment }> = ({ judgment }) => {
  console.log(judgment);

  const logEvent = useEventLogger();
  const history = useHistory();
  const { section: activeSection } = useParams();

  const tabs: EuiTabbedContentTab[] = useMemo(
    () =>
      [
        {
          id: 'decisions',
          name: 'Decisions',
          content: <Decisions judgment={judgment} />,
        },
        {
          id: 'procedural-Background',
          name: 'Procedural Background',
          content: <ProceduralBackground judgment={judgment} />,
        },
        {
          id: 'parties-and-representation',
          name: 'Parties & Representation',
          content: <PartiesRepresentation judgment={judgment} />,
        },
        {
          id: 'authorship-and-content',
          name: 'Authorship & Content',
          content: <AuthorshipContent judgment={judgment} />,
        },
        {
          id: 'commentary-and-discussion',
          name: 'Commentary & Discussion',
          content: <div />,
        },
      ].map(tab => ({ ...tab, content: <TabContainer>{tab.content}</TabContainer> })),
    [judgment],
  );

  const [selectedTabIdx, setSelectedTabIdx] = useState<number>(0);
  const selectedTab = tabs[selectedTabIdx];

  /**
   * We maintain the tab state by syncing it with the `activeSection` param in
   * the path. The `id` of a tab should match its `activeSection`.
   */
  const getTabById = (id?: string): EuiTabbedContentTab | undefined =>
    tabs.find(tab => tab.id === id);

  const getIdxById = (id?: string): number => {
    let idx = 0;
    tabs.forEach((tab, i) => {
      if (tab.id == id) idx = i;
    });
    return idx;
  };

  const onTabClick = (clickedTab: EuiTabbedContentTab) => {
    logEvent('judgment-detail-tab', clickedTab.id);
    // const newTab = tabs.find(tab => tab.id === selectedTab.id);
    // if (newTab) setSelectedTab(newTab);
    history.push(`/judgments/${judgment.id}/${clickedTab.id}`);
  };

  useEffect(() => {
    if (selectedTab.id !== activeSection) {
      const newTab = getTabById(activeSection);

      /**
       * Redirect to initial tab if an invalid path is given
       */
      if (!newTab) setSelectedTabIdx(0);
      else setSelectedTabIdx(getIdxById(newTab.id));
    }
  }, [activeSection, history.location.pathname]);

  return (
    <AppPage>
      <EuiPageBody>
        <EuiFlexGroup>
          <EuiFlexItem>
            <h1>{judgment.short_name}</h1>
            <EuiHorizontalRule margin="xs" />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup>
          <EuiFlexItem grow={2}>
            <EuiStat
              description={
                <>
                  {judgment.legal_year.year_string} · {judgment.term}
                </>
              }
              title={judgment.neutral_citation}
              titleSize="s"
            />
          </EuiFlexItem>

          <EuiFlexItem grow={3}>
            <EuiFlexGroup gutterSize="s">
              <EuiButtonEmpty iconType="document" href={judgment.pdf_judgment_url} target="_blank">
                Judgment
              </EuiButtonEmpty>
              <EuiButtonEmpty iconType="document">Press Summary</EuiButtonEmpty>
              {/* </EuiFlexGroup>

                <EuiFlexGroup gutterSize="s" alignItems="center"> */}
              <EuiButtonEmpty iconType="tableOfContents" href={judgment.bailii_url}>
                BAILII
              </EuiButtonEmpty>
              <EuiButtonEmpty iconType="reporter">UKSC Blog</EuiButtonEmpty>
            </EuiFlexGroup>
          </EuiFlexItem>

          {/* <EuiFlexItem>
                <br />
                {judgment.alternative_citations.length > 0 && (
                  <EuiAccordion
                    id="accordion9"
                    buttonContent={`${judgment.alternative_citations.length} alternative citations`}
                    paddingSize="s"
                  >
                    <EuiText>
                      {judgment.alternative_citations.map(citation => (
                        <>
                          {citation.citation}
                          <br />
                        </>
                      ))}
                    </EuiText>
                  </EuiAccordion>
                )}
              </EuiFlexItem> */}

          <EuiFlexItem grow={3}>
            <EuiBadgeGroup>
              {judgment.subject_tags.map(tag => (
                <EuiFlexItem grow={false}>
                  <EuiBadge href={`/tags/${tag.id}`} color="primary">
                    {tag.title}
                  </EuiBadge>
                </EuiFlexItem>
              ))}
            </EuiBadgeGroup>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiTabbedContent
          expand
          display="condensed"
          tabs={tabs}
          selectedTab={selectedTab}
          onTabClick={onTabClick}
        />
      </EuiPageBody>
    </AppPage>
  );
};
