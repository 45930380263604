import React from 'react';
import FirebaseAuth from 'react-firebaseui/FirebaseAuth';
import { default as Firebase } from 'firebase';

import { useFirebase, useAuth } from 'hooks';

export const Login: React.FC = () => {
  const firebase = useFirebase();
  const user = useAuth();

  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      Firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      Firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
  };

  return (
    <div>
      <h1>My App</h1>
      <p>Please sign-in:</p>
      {user ? (
        <>
          Signed in as: {user.email}.{' '}
          <button onClick={() => firebase.auth().signOut()}>Click here to sign out</button>
        </>
      ) : (
        <FirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      )}
    </div>
  );
};
