import React from 'react';
import moment from 'moment';

import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import { Judgment } from 'types';

type ProvenanceProps = { judgment: Judgment };

export const Provenance: React.FC<ProvenanceProps> = ({ judgment }) => {
  return (
    <EuiFlexGroup>
      <EuiFlexItem>
        {judgment.appeal_sources.map(source => (
          <>{source.media_neutral_citation}</>
        ))}
      </EuiFlexItem>

      <EuiFlexItem>
        Heard on:{' '}
        {judgment.hearing_sessions.map(session => (
          <>
            {moment(session.date).format('dddd, MMMM Do YYYY')}{' '}
            {session.session_type !== 'not_specified' && `(${session.session_type})`} <br />
          </>
        ))}
      </EuiFlexItem>
      <EuiFlexItem>
        Judgment handed down on {moment(judgment.hand_down_date).format('dddd, MMMM Do YYYY')},{' '}
        {judgment.days_from_hearing_to_judgment} days after last hearing
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default Provenance;
