import React from 'react';
import { Link } from 'react-router-dom';
import { EuiBadge } from '@elastic/eui';

import { Instructor } from './Instructor';
import { Advocate } from './Advocate';
import { ranked } from 'utils';

import * as types from 'types';

export const Party: React.FC<{
  party: types.Party;
}> = ({ party }) => {
  return (
    <div>
      <div>
        {party.named_actors.map(actor => (
          <>
            {' '}
            <Link to={`/actors/${actor.actor.id}`} className="euiLink euiLink--primary">
              {actor.styled_as}
            </Link>
          </>
        ))}

        <br />
        {party.unnamed_actors.map(actor => actor.styled_as)}
      </div>
      <EuiBadge color="hollow">{party.party_type.toUpperCase()}</EuiBadge>
      <br />
      <br />
      <div>
        <strong>Counsel</strong>
        <br />
        {ranked(party.advocates).map(advocate => (
          <>
            <Advocate advocate={advocate} />
            <br />
          </>
        ))}
      </div>
      <br />
      <div>
        <strong>Instructors</strong>
        {ranked(party.instructors).map(instructor => (
          <>
            <Instructor instructor={instructor} />
            <br />
          </>
        ))}
      </div>
    </div>
  );
};
