import React from 'react';

import { Box, Flex } from 'reflexbox';
import { pickChildrenByComponent, disallowChildrenByComponent } from 'utils';

type GroupProps = {
  children: any;
};

export const Group: React.FC<GroupProps> = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const sections = pickChildrenByComponent(children, Section);

  return <Flex>{sections}</Flex>;
};

type SectionProps = {
  secondary?: boolean;
  oneThird?: boolean;
  children: any;
};

export const Section: React.FC<SectionProps> = ({
  children,
  secondary = false,
  oneThird = false,
}) => {
  disallowChildrenByComponent(children, [Section, Group]);

  if (secondary && oneThird)
    throw Error('Sections must either set the secondary or the oneThird prop, not both');

  let flex = '1 1 auto';
  let width = 1;

  if (secondary) {
    flex = '1 0 auto';
    width = 1 / 4;
  }

  if (oneThird) {
    flex = '1 0 auto';
    width = 1 / 3;
  }

  return (
    <Box
      flex={flex}
      width={width}
      sx={{
        p: 2,
      }}
    >
      {children}
    </Box>
  );
};

type LayoutComposition = {
  Section: typeof Section;
  Group: typeof Group;
};

type LayoutProps = {
  children: any;
};

export const Layout: React.FC<LayoutProps> & LayoutComposition = ({ children }) => {
  const sections = pickChildrenByComponent(children, [Section, Group]);

  return (
    <Flex flexWrap="wrap" flexDirection="column">
      {sections}
    </Flex>
  );
};

Layout.Section = Section;
Layout.Group = Group;

export default Layout;
