import React from 'react';

import {
  EuiButtonIcon,
  EuiHeader,
  EuiHeaderLink,
  EuiHeaderLinks,
  EuiHeaderSectionItem,
  EuiHeaderSection,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiSuggest,
  EuiText,
} from '@elastic/eui';

export const AppPage: React.FC = ({ children }) => (
  <>
    <EuiText>
      <EuiPage>
        <EuiPageBody>
          <EuiPageHeader>
            <EuiPageHeaderSection>
              <EuiHeader position="fixed">
                <EuiHeaderSection side="right">
                  <EuiHeaderSectionItem>
                    <h3>AppearedIn</h3>
                  </EuiHeaderSectionItem>
                </EuiHeaderSection>

                <EuiHeaderSection side="right">
                  <EuiHeaderSectionItem>
                    <EuiHeaderLinks>
                      <EuiHeaderLink iconType="documents" href="/judgments">
                        Judgments
                      </EuiHeaderLink>
                      <EuiHeaderLink iconType="timeline" href="/judgments">
                        Justices
                      </EuiHeaderLink>

                      <EuiHeaderLink iconType="user" href="#">
                        Parties &amp; Counsel
                      </EuiHeaderLink>
                      <EuiHeaderLink iconType="stats" href="#">
                        Statistics
                      </EuiHeaderLink>
                      <EuiHeaderLink iconType="visLine" href="#">
                        Trends
                      </EuiHeaderLink>
                    </EuiHeaderLinks>
                  </EuiHeaderSectionItem>
                </EuiHeaderSection>
                <EuiHeaderSection side="right">
                  <EuiHeaderSectionItem>
                    <EuiSuggest suggestions={[]} />
                  </EuiHeaderSectionItem>
                </EuiHeaderSection>
                <EuiHeaderSection side="right">
                  <EuiButtonIcon iconType="managementApp" />
                </EuiHeaderSection>
              </EuiHeader>
            </EuiPageHeaderSection>
          </EuiPageHeader>
          <EuiPageContent>{children}</EuiPageContent>
        </EuiPageBody>
      </EuiPage>
    </EuiText>
  </>
);

export default AppPage;
