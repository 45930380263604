import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

export const NoMatch: React.FC = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  return (
    <div>
      <h1>Page not found</h1>
      <p>
        The URL <strong>{pathname}</strong> is unknown.
        <br />
        <button type="button" onClick={history.goBack}>
          Click here to go back.
        </button>
      </p>
    </div>
  );
};

export default NoMatch;
