import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import {
  EuiLink,
  EuiHorizontalRule,
  EuiAccordion,
  EuiSpacer,
  EuiTabbedContent,
  EuiTabbedContentTab,
} from '@elastic/eui';

import { useEventLogger } from 'hooks';

import { Actor } from 'types/Actor';

type AppearancesProps = { actor: Actor };

export const Appearances: React.FC<AppearancesProps> = ({ actor }) => {
  const logEvent = useEventLogger();

  return (
    <>
      {Object.entries(actor.appearances)
        .filter(([_, appearance]) => appearance.count > 0)
        .map(([appearanceType, appearance]) => (
          <EuiAccordion
            id={appearanceType}
            onToggle={isOpen => logEvent('actor_detail_tab_open', { opened: isOpen })}
            buttonContent={`Apperances as ${appearance.label} (${appearance.count})`}
          >
            {appearance.judgments.map(judgment => (
              <>
                <EuiLink href={`/judgments/${judgment.id}`}>
                  {judgment.neutral_citation} | {judgment.short_name}{' '}
                </EuiLink>
                <br />
              </>
            ))}
          </EuiAccordion>
        ))}
    </>
  );
};
export default Appearances;
