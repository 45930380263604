import { IRouteDefinition } from 'providers';

import * as views from '.';

const routeConfig: IRouteDefinition[] = [
  {
    path: '/',
    exact: true,
    component: views.FrontPage,
    authenticated: true,
  },
];

export default routeConfig;
