import React, { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { ApiContext } from './ApiContext';
import { useAuth } from 'hooks';

type ApiProviderProps = {
  config?: AxiosRequestConfig;
  children: any;
};

export const ApiProvider: React.FC<ApiProviderProps> = ({ config, children }) => {
  const auth = useAuth();
  const [idToken, setIdtoken] = useState('');

  const apiConfig = { baseURL: `${process.env.REACT_APP_API_BASE_URI}`, headers: {} };

  if (idToken)
    apiConfig['headers'] = {
      Authorization: `Bearer ${idToken}`,
    };

  useEffect(() => {
    auth?.getIdToken().then(token => {
      setIdtoken(token);
      console.log(token);
    });
  }, [auth]);

  return <ApiContext.Provider value={{ ...apiConfig, ...config }}>{children}</ApiContext.Provider>;
};

export default ApiProvider;
