import React from 'react';

type HeroImageProps = {
  url: string;
};

export const HeroImage: React.FC<HeroImageProps> = ({ url }) => {
  /**
   * Sizes:
   */
  return <img src={url} />;
};

export default HeroImage;
