import { useCallback, useContext, useState } from 'react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { ApiContext } from './ApiContext';

export type UrlSetterArgs = { [key: string]: any };
export type UrlSetterFunc = (params: UrlSetterArgs) => string;

/**
 * Arguments passed to the requester method returned by the hook.
 */
export type RequesterMethodArgs = {
  urlParams?: UrlSetterArgs;
  data?: object | any[];
};

export type IUseApiResponse<T = any> = [
  {
    /**
     * True if request is currently awaiting a response.
     */
    loading: boolean;

    /**
     * Data from the response body.
     */
    data: T;

    /**
     * Any errors caught during the request cycle. Note that this will only be set if a rejected promise from the requester object is caught.
     */
    error: any;

    /**
     * The HTTP status code
     */
    status: number;

    /**
     * True unless and until the request has been triggered at least once
     */
    initialLoad: boolean;

    /**
     * True if no request have been triggered, or if currently awaiting a
     * response
     */
    pendingOrLoading: boolean;

    /**
     * The full Axios response object
     */
    response?: AxiosResponse<T>;
  },
  /**
   * Request Trigger function. Call with optional body data for
   * POST/PATCH/PUT requests.
   *
   * Return a Promise that resolves with the data from response body, or any
   * errors thrown.
   */
  (params?: RequesterMethodArgs) => Promise<AxiosResponse<T>>,
];

/**
 * The useApi hook supports all Axios request config arguments, with the
 * exception of `url`, which in our case can also be a method.
 */
export interface IUseApiArgs extends Omit<AxiosRequestConfig, 'url'> {
  /**
   * The request URL, can be a string or a function which accepts the
   * `urlParams` object from the requester method and returns an URL.
   */
  url: UrlSetterFunc | string;
}

export const useApi = <TApiResponseData = any>(
  hookConfig: IUseApiArgs,
): IUseApiResponse<TApiResponseData> => {
  const [initialLoad, setInitialLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState<TApiResponseData>();
  const [error, setError] = useState();
  const [status, setStatus] = useState();
  const [responseObj, setResponseObj] = useState<AxiosResponse<TApiResponseData>>();

  /**
   * Store a stable reference to the hook arguments for use in useCallback
   * dependency list
   */
  const [hookArgs] = useState(hookConfig);

  /**
   * The default Axios configuration can be overriden by
   * providing values in ApiContext
   */
  const globalConfig = useContext(ApiContext);

  const handleResponse = useCallback((response: AxiosResponse<TApiResponseData>): void => {
    setResponseObj(response);
    setResponseData(response.data);
    setStatus(response.status);
  }, []);

  const finishLoading = useCallback((): void => {
    setLoading(false);
    setInitialLoad(false);
  }, []);

  /**
   * Requester function
   *
   * Wrapped in useCallback because we want to the requester method to have a
   * stable reference so we can use it as a dependency in useEffect.
   */
  const memoizedRequesterMethod = useCallback(
    /**
     * The requester method should be suitable for use as a hook dependency
     * (as below if asEffect is set to true), or as a prop.
     *
     * Wrap it in useCallback to ensure a stable reference.
     */
    ({ urlParams, data }: RequesterMethodArgs = {}): Promise<AxiosResponse<TApiResponseData>> => {
      /**
       * Merge request config with global axios config. If empty,
       * globalConfig defaults to {}
       */
      const axiosConfig: AxiosRequestConfig = {
        ...globalConfig,
        ...hookArgs,
        url:
          /**
           * Invoke URL Setter if possible
           */
          typeof hookArgs.url === 'function' && urlParams
            ? hookArgs.url(urlParams)
            : hookArgs.url.toString(),
        data,
      };

      /**
       * Return a Promise to enable the calling code to chain
       * network requests in the correct order.
       */
      return new Promise((resolve, reject) => {
        setLoading(true);

        try {
          axios(axiosConfig)
            .then((response: AxiosResponse<TApiResponseData>) => {
              handleResponse(response);
              resolve(response);
            })
            .catch(err => {
              if (err.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                handleResponse(err.response);
              }

              setError(err);
              reject(err);
            })
            .finally(finishLoading);
        } catch (err) {
          reject(err);
          setError(err);
          finishLoading();
        }
      });
    },
    [hookArgs, globalConfig, finishLoading, handleResponse],
  );

  return [
    {
      loading,
      /**
       * Coerce type to the generic: consumers will generally use loading and
       * error checking as typeguards and can thus better determine whether or
       * not data can be null.
       */
      data: responseData!, // eslint-disable @typescript-eslint/no-non-null-assertion
      error,
      status,
      initialLoad,
      pendingOrLoading: initialLoad || loading,
      response: responseObj,
    },
    memoizedRequesterMethod,
  ];
};

export default useApi;
