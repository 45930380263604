import React, { useState } from 'react';

import { Suggest } from 'components';
import { useApi } from '../Api';
import { Actor, Judgment } from 'types';

type CombinedSearchProps = {};

export const CombinedSearch: React.FC<CombinedSearchProps> = () => {
  const [{ loading }, doSearch] = useApi({
    url: ({ query }) => encodeURI(`/appearedin/search/?q=${query}`),
  });

  const [judgments, setJudgments] = useState<Judgment[]>([]);
  const [actors, setActors] = useState<Actor[]>([]);

  const onSearch = (query: string) => {
    doSearch({
      urlParams: {
        query,
      },
    }).then(response => {
      setJudgments(response.data.judgments);
      setActors(response.data.actors);
    });
  };

  return <Suggest onSearch={onSearch} actors={actors} judgments={judgments} loading={loading} />;
};
export default CombinedSearch;
