import React from 'react';

import {
  EuiFlexGroup,
  EuiFormRow,
  EuiFlexItem,
  EuiPageContentBody,
  EuiSuggest,
} from '@elastic/eui';

import { AppPage } from 'components';
import { CombinedSearch } from 'providers';

export const FrontPage: React.FC = () => (
  <>
    <AppPage>
      <EuiPageContentBody>
        <EuiFlexGroup>
          <EuiFlexItem grow={2}>
            <h2>The UK Supreme Court</h2>
            <br />
            <EuiFormRow fullWidth>
              <CombinedSearch />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <h4>Recent judgments</h4>
            <ol>
              <li>
                [2020] UKSC 4: <i>John v Doe Limited</i>
              </li>
            </ol>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPageContentBody>
    </AppPage>
  </>
);

export default FrontPage;
