import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { EuiProgress } from '@elastic/eui';

import { useApi } from 'providers';
import { Actor } from 'types/Actor';

import { ActorDetail } from './ActorDetail';

type ActorDetailPageProps = {};

export const ActorDetailPage: React.FC<ActorDetailPageProps> = () => {
  const { id } = useParams();
  const [{ pendingOrLoading, data: actor, status }, request] = useApi<Actor>({
    url: `/appearedin/actors/${id}`,
    validateStatus: status => [200].includes(status),
  });

  useEffect(() => {
    request().catch(err => console.error(err));
  }, [request]);

  if (pendingOrLoading) return <EuiProgress size="l" color="accent" />;

  if (status !== 200) return <div />;

  return <ActorDetail actor={actor} />;
};

export default ActorDetailPage;
