import React from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';

import { NoMatch } from 'screens';
import { useAuth } from 'hooks';

export interface IRouteDefinition {
  /**
   * The URI path used to match this route
   */
  path: string;

  /**
   * The Component to render for this route
   */
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;

  /**
   * An array of sub-routes for this route
   */
  routes?: IRouteDefinition[];

  /**
   * Whether the path should be matched partially or exactly
   */
  exact: boolean;

  /**
   * Does the route required authentication
   */
  authenticated?: boolean;
}

type RouteWithSubRoutesProps = IRouteDefinition;

const RouteWithSubRoutes: React.FC<RouteWithSubRoutesProps> = ({
  path,
  component,
  routes,
  exact,
  authenticated = true,
}: RouteWithSubRoutesProps) => {
  const user = useAuth();
  if (authenticated && !user) {
    /**
     *
     */
    return <Redirect to={`/login?redirect=${path}`} />;
  }

  return (
    <Route
      path={path}
      render={(): React.ReactElement => React.createElement(component, { path, exact, routes })}
    />
  );
};

const renderRoutes = (
  routes: IRouteDefinition[] = [],
  showNoMatch: boolean,
): React.ReactElement => {
  if (routes.length === 0) {
    return <Route path="*" component={NoMatch} />;
  }

  return (
    <Switch>
      {routes.map(route => {
        if (route.routes && route.exact) {
          /**
           * If a route definition has child routes and exact matching, the
           * parent child routes will never be reached.
           */
          throw Error('Route definitions with subroutes must set the exact property to false');
        }

        return React.createElement(RouteWithSubRoutes, {
          key: route.path,
          component: route.component,
          path: route.path,
          exact: route.exact,
          routes: route.routes,
          authenticated: route.authenticated,
        });
      })}

      {/**
       * Because renderRoutes calls can be nested, we only want to show the NoRoute matcher when
       * that makes sense in the calling context.
       */
      showNoMatch && <Route path="*" component={NoMatch} />}
    </Switch>
  );
};

type RouteConfigProps = {
  /**
   * An array of route definitions
   */
  routes: IRouteDefinition[];

  /**
   * Whether to display a Not Found component if no routes are matched
   */
  showNoMatch?: boolean;
};

export const RouteConfig: React.FC<RouteConfigProps> = ({
  routes,
  showNoMatch = true,
}: RouteConfigProps) => renderRoutes(routes, showNoMatch);

export default RouteConfig;
