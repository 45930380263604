import React from 'react';
import { Link } from 'react-router-dom';
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiProgress,
  EuiStat,
  EuiBadge,
  EuiIconTip,
} from '@elastic/eui';

import { Judgment } from 'types';
import { EuiSpacer } from '@elastic/eui';

type AuthorshipContentProps = { judgment: Judgment };

export const AuthorshipContent: React.FC<AuthorshipContentProps> = ({ judgment }) => {
  console.log(judgment.authorships);
  const { authorships } = judgment;
  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiStat title={judgment.page_count} description="Page count" />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiStat title={judgment.paragraph_count} description="Paragraph count" />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup>
        <EuiFlexItem>
          {authorships.map(authorship => (
            <EuiFlexGroup>
              <EuiFlexItem grow={1}>
                <Link to={`/actors/${authorship.actor.id}`}>{authorship.actor.justice_style}</Link>
                {authorship.authorship_type === 'non_substantive' && (
                  <>
                    <br />

                    <span>
                      <EuiBadge color="hollow">Non-substantive</EuiBadge>{' '}
                      <EuiIconTip
                        type="iInCircle"
                        content="Authorships are classifed as non-substantive when they contain little to no legal reasoning and simply serve to express (dis)agreement with another Justice"
                      />
                    </span>
                  </>
                )}
              </EuiFlexItem>
              <EuiFlexItem grow={5}>
                <EuiProgress max={100} value={authorship.percentage} size="m" />
                <EuiFlexItem>
                  <EuiSpacer size="s" />
                  <span>
                    Paragraphs {authorship.paragraph_start} - {authorship.paragraph_end} (
                    <strong>{authorship.paragraph_length}</strong> paragraphs, or{' '}
                    <strong>{authorship.percentage}%</strong> of the judgment)
                  </span>
                </EuiFlexItem>
                <EuiSpacer size="l" />
              </EuiFlexItem>
            </EuiFlexGroup>
          ))}
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

export default AuthorshipContent;
