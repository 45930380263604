import React from 'react';

const getDisplayNames = (component: React.ReactNode | React.ReactNode[]): string => {
  const componentTypes = Array.isArray(component) ? component : [component];

  return componentTypes
    .map(componentType => {
      /**
       * TODO: Find a good way to get human readable display names for the
       * component types.
       */
      return componentType;
    })
    .join(', ');
};

/**
 * Filter passed children according to component type.
 *
 * @param children The children prop passed by the calling component's parent
 * @param component The component type to filter against
 */
export const findChildrenByComponent = (
  children: React.ReactNode,
  component: React.ReactNode | React.ReactNode[],
): React.ReactNode[] => {
  const needle: React.ReactNode[] = [];
  const componentType = Array.isArray(component) ? component : [component];

  React.Children.forEach(children, child => {
    if (React.isValidElement(child) && componentType.includes(child.type)) {
      needle.push(child);
    }
  });

  return needle;
};

/**
 * Disallow children according to component type
 *
 * @param children The children prop passed by the calling component's parent
 * @param component The component type to filter against
 * @throws Will throw an error if there are children of specified type
 */

export const disallowChildrenByComponent = (
  children: React.ReactNode,
  component: React.ReactNode | React.ReactNode[],
): void => {
  const pickedChildren = findChildrenByComponent(children, component);

  if (pickedChildren.length > 0)
    throw Error(`You cannot have children of type ${getDisplayNames(component)}`);
};

/**
 * Only pick the child according to component type.
 *
 * @param children The children prop passed by the calling component's parent
 * @param component The component type to filter against
 * @throws Will throw an error if there are children of type other than those of
 * the specified type
 */
export const pickChildrenByComponent = (
  children: React.ReactNode,
  component: React.ReactNode | React.ReactNode[],
): React.ReactNode[] => {
  const pickedChildren = findChildrenByComponent(children, component);

  if (pickedChildren.length !== React.Children.count(children)) {
    throw Error(`Encountered children that are not of type ${getDisplayNames(component)}`);
  }

  return pickedChildren;
};

/**
 * Pick a single child according to component type.
 *
 * @param children The children prop passed by the calling component's parent
 * @param component The component type to filter against
 * @throws Will throw an error if there are multiple children of the specified
 * type
 */
export const pickChildByComponent = (
  children: React.ReactNode,
  component: React.ReactNode,
): React.ReactNode | null => {
  const pickedChildren = findChildrenByComponent(children, component);
  if (pickedChildren.length > 1 && component)
    throw Error(`Cannot pick child: found multiple children of type ${getDisplayNames(component)}`);

  return pickedChildren.length === 1 ? pickedChildren[0] : null;
};
