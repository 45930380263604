import React from 'react';

import * as types from 'types';
import { PartyGroups } from './PartyGroups';

type PartiesRepresentationProps = {
  judgment: types.Judgment;
};

export const PartiesRepresentation: React.FC<PartiesRepresentationProps> = ({ judgment }) => (
  <div>
    <PartyGroups groups={judgment.party_groups} />
  </div>
);

export default PartiesRepresentation;
