import React, { useEffect, useState } from 'react';
import { EuiInMemoryTable, EuiLink } from '@elastic/eui';

import { AppPage } from 'components';
import { useApi } from 'providers';
import * as types from 'types';
import { EuiPageBody } from '@elastic/eui';

type JudgmentsOverviewProps = {
  judgments: types.ShortJudgment[];
};

export const JudgmentsOverview: React.FC<JudgmentsOverviewProps> = ({ judgments }) => {
  const columns = [
    {
      field: 'short_name',
      name: 'Name',
      sortable: true,
      render: (value: any, item: any) => <EuiLink href={`/judgments/${item.id}`}>{value}</EuiLink>,
    },
    {
      field: 'neutral_citation',
      name: 'Neutral Citation',
      sortable: true,
    },
    {
      field: 'hand_down_date',
      name: 'Hand-down Date',
      sortable: true,
    },
  ];

  return (
    <AppPage>
      <EuiPageBody>
        <EuiInMemoryTable
          sorting
          items={judgments}
          columns={columns}
          pagination={{
            initialPageSize: 15,
            pageSizeOptions: [15, 25, 50, 75, 100],
          }}
        />
      </EuiPageBody>
    </AppPage>
  );
};
