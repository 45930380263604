import React from 'react';
import { Box, Flex } from 'reflexbox';

type ItemProps = {
  children: React.ReactNode;
  fill?: boolean;
};

const Item: React.FC<ItemProps> = ({ children, fill = false }) => (
  <Box flexGrow={fill ? 1 : 0}>{children}</Box>
);

type StackProps = {
  children: any;
  distribute?: 'evenly' | 'proportionally';
};

type StackComposition = {
  Item: typeof Item;
};

export const Stack: React.FC<StackProps> & StackComposition = ({ children, distribute }) => {
  const items = React.Children.map(children, child => {
    if (distribute === 'evenly') {
      return <Item fill>{child}</Item>;
    }

    if (React.isValidElement(child) && child.type === Item) {
      return child;
    }

    return <Item>{child}</Item>;
  });

  return (
    <Flex flexWrap="wrap" alignItems="stretch">
      {items}
    </Flex>
  );
};

Stack.Item = Item;

export default Stack;
