import React, { createContext, useContext, useState, useEffect } from 'react';
import firebase, { User } from 'firebase';
import { EuiProgress } from '@elastic/eui';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

const app = firebase.initializeApp(firebaseConfig);

export const useFirebase = (): firebase.app.App => {
  return app;
};

type AuthProviderProps = {
  children: any;
};

const AuthContext = createContext<User | null>(null);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const auth = app.auth();

  console.log('User:', user);

  useEffect(() => {
    const listener = auth.onAuthStateChanged(user => {
      setUser(user);
      setInitialLoad(false);
    });

    return () => listener();
  }, [auth]);

  if (initialLoad) return <EuiProgress size="xs" color="accent" />;

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};

export const useAuth = (): User | null => useContext(AuthContext);
