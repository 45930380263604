import React from 'react';
import { Link } from 'react-router-dom';
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiBasicTable,
  EuiLink,
  EuiHealth,
  EuiCard,
  EuiSpacer,
  EuiIconTip,
  EuiHeader,
  EuiStat,
} from '@elastic/eui';

import { countBy } from 'lodash';

import * as types from 'types';
import { Decision } from './Decision';

type DecisionsProps = {
  judgment: types.Judgment;
};

export const Decisions: React.FC<DecisionsProps> = ({ judgment }) => {
  const unanimousCount = {
    true: 0,
    false: 0,
    ...countBy(judgment.decisions, d => d.is_unanimous),
  };

  const decisionCount = judgment.decisions.length;
  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem>
          <ul>
            <li>
              This judgment contained{' '}
              <strong>
                {decisionCount === 1 ? `${decisionCount} decision` : `'${decisionCount} decisions`}
              </strong>{' '}
              <EuiIconTip type="iInCircle" content="Judgments and decisions." />
            </li>
            <li>
              {unanimousCount['true'] || 'none'} {unanimousCount['true'] === 1 ? 'was' : 'were'}{' '}
              decided unanimously, {unanimousCount['false'] || 'none'}{' '}
              {unanimousCount['false'] === 1 ? 'was' : 'were'} decided with a divided vote
            </li>
            <li>
              The judgment as a whole is classified as{' '}
              <strong>
                {judgment.is_unanimous ? (
                  <EuiHealth color="success">Unanimous</EuiHealth>
                ) : (
                  <EuiHealth color="warning">Divided</EuiHealth>
                )}
              </strong>
            </li>
          </ul>
        </EuiFlexItem>
        <EuiFlexItem>
          <span>
            The case was heard by a panel of <strong>{judgment.panel.length} Justices</strong>:
            <br />
            {judgment.panel
              .flatMap(actor => [
                <Link to={`/actors/${actor.id}`}>{actor.justice_style}</Link>,
                ', ',
              ])
              .slice(0, -1)}
          </span>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="l" />
      {judgment.decisions.map(decision => (
        <>
          <EuiFlexGroup>
            <EuiFlexItem>
              <Decision key={decision.id} decision={decision} />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size="l" />
        </>
      ))}
    </>
  );
};

export default Decisions;
